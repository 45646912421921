@use "variables/color";
@use "mixins/breakpoint";

.container {
  display: grid;
  grid-gap: 24px;
  position: relative;
  z-index: 2;

  @include breakpoint.tablet {
    grid-gap: 40px;
  }
}

.homeOfferBoxes {
  background-color: color.$almostBlack;
  color: color.$white;
  margin: var(--screen-sections-gap) 0;
  overflow: hidden;
  padding: 40px 0;
  position: relative;

  &::before,
  &::after {
    border-radius: 50%;
    content: "";
    display: block;
    filter: blur(250px);
    height: 700px;
    position: absolute;
    width: 700px;
  }

  &::before {
    background: #3d187a;
    right: calc(50% + 200px);
    top: -300px;
  }

  &::after {
    background: #d619ff;
    bottom: -600px;
    left: calc(50% + 200px);
  }
}
