@use "mixins/breakpoint";

.tabBtnGroup {
  display: grid;
  grid-template-columns: repeat(var(--items-length, 1), minmax(100px, 1fr));

  @include breakpoint.tablet {
    border-radius: 0;
    grid-gap: 8px;
    margin: 0 auto;
    width: fit-content;
  }
}
