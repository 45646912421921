@use "variables/color";

.onboarding {
  margin: 64px auto;
}

.title {
  margin: 24px 0;
  font-size: 18px;
  font-weight: 700;
  text-align: center;

  @media (min-width: 992px) {
    font-size: 26px;
    margin: 32px 0;
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 280px);
  column-gap: 24px;
  align-items: stretch;
  width: fit-content;
  max-width: 100%;
  padding: 12px;
  margin: 0 auto;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  &::-webkit-scrollbar {
    height: 0;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px 32px;
  background: color.$white;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  text-align: center;
  scroll-snap-align: center;
}

.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background: color.$violet;
  border-radius: 16px;
  color: color.$white;
  height: 56px;
  width: 56px;
}

.itemTitle {
  flex: 1;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  margin: 16px 0 8px;

  .pink {
    color: color.$club;
    text-decoration: none;
  }
}

.itemDescription {
  margin: 0;
  color: #16082999;
  font-size: 14px;
  line-height: 18px;
}
