@use "variables/color";

.argumenters {
  display: grid;
  grid-gap: 12px;
}

.separator {
  border-color: transparentize(color.$white, 0.5);
  border-width: 1px 0 0 0;
  margin: 0;
}
