@use "mixins/breakpoint";

.collection {
  --item-size: 75px;
  align-content: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;

  @include breakpoint.tablet {
    --item-size: 125px;
    gap: 16px;
  }

  @media (min-width: 1280px) {
    --item-size: 112px;
    gap: 8px 16px;
    height: 472px;
    margin: 0;
    padding: calc(var(--item-size) * 0.5) 0;
    transform: none;
    width: 100%;
  }
}

.section {
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-wrap: nowrap;
  gap: 4px;
  overflow: hidden;
  width: 100%;

  &:first-child {
    justify-content: start;
  }

  &:last-child {
    justify-content: end;
  }

  @include breakpoint.tablet {
    gap: 16px;
  }

  @media (min-width: 1280px) {
    display: contents;

    &:first-child {
      > *:nth-child(4) {
        margin-top: calc(var(--item-size) * -0.5);
      }
    }

    &:last-child {
      > *:nth-child(2) {
        margin-bottom: calc(var(--item-size) * -0.5);
      }
    }
  }
}
