@use "variables/color";

.collectionItem {
  aspect-ratio: 1/1;
  border-radius: 4px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.25);
  display: block;
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  width: var(--item-size, 112px);
  z-index: 1;

  > img {
    height: 100%;
    width: 100%;
  }

  @media (min-width: 1280px) {
    &:hover {
      box-shadow: 0 0 32px color.$club;
      transform: scale(1.1);
      z-index: 2;
    }
  }
}
