@use "variables/color";
@use "mixins/breakpoint";

.aboutClub {
  position: relative;
  margin: var(--screen-sections-gap) 0;
  color: color.$white;
}

.bgPicture {
  position: absolute;
  inset: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #110f2f;
  }
}

.content {
  display: grid;
  grid-gap: 22px;
  grid-template-columns: 1fr;
  justify-items: center;
  padding-bottom: 30px;
  padding-top: 30px;

  @media (min-width: 992px) {
    align-items: center;
    grid-template-columns: 1fr 350px 1fr;
  }

  @include breakpoint.desktop {
    grid-template-columns: 1fr 450px 1fr;
  }
}

.title {
  font-size: 26px;
  font-weight: 700;
  margin: 0;
  text-align: center;

  @media (min-width: 992px) {
    grid-column: 1/4;
  }

  @include breakpoint.desktop {
    font-size: 48px;
  }
}

.image {
  max-width: 300px;
  width: 100%;
  height: auto;

  @media (min-width: 992px) {
    grid-column: 2;
    max-width: 100%;
  }
}

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 22px;
  list-style: none;
  margin: 0;
  max-width: 300px;
  padding: 0;
  width: 100%;

  @media (min-width: 992px) {
    grid-row: 2;
    grid-column: 3;
    justify-self: start;

    &__first {
      grid-column: 1;
      justify-self: end;
    }
  }

  @include breakpoint.desktop {
    grid-gap: 40px;
    max-width: 380px;
  }
}

.item {
  --icon-size: 40px;
  --svg-size: 20px;
  display: grid;
  align-items: center;
  gap: 12px;
  grid-template-columns: var(--icon-size) 1fr;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;

  b,
  strong {
    color: color.$club;
    font-weight: inherit;
  }

  p {
    margin: 0;
  }

  @include breakpoint.desktop {
    --icon-size: 64px;
    --svg-size: 40px;
    font-size: 15px;
    gap: 24px;
    line-height: 140%;
  }

  @media (min-width: 1524px) {
    font-size: 18px;
  }
}

.iconWrapper {
  align-items: center;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  color: #fff;
  display: flex;
  justify-content: center;
  height: var(--icon-size);
  width: var(--icon-size);

  svg {
    height: var(--svg-size);
    width: var(--svg-size);
  }

  @include breakpoint.desktop {
    border-radius: 16px;
  }
}

.moreButton {
  background: color.$club;
  border-radius: 4px;
  color: color.$white;
  font-size: 16px;
  font-weight: 700;
  max-width: 300px;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  width: 100%;

  @media (min-width: 992px) {
    grid-column: 1/4;
  }
}
