@use "variables/color";

.dotButton {
  appearance: none;
  background-color: color.$gray;
  touch-action: manipulation;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  width: var(--dot-size);
  height: var(--dot-size);
  border-radius: 50%;

  &__active {
    background-color: color.$green;
  }
}

.dotButtons {
  --dot-size: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-height: var(--dot-size);
  margin-top: 16px;
}
