@use "mixins/breakpoint";

.pageInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  max-width: 800px;
  margin: var(--screen-sections-gap) auto;

  @include breakpoint.tablet {
    gap: 16px;
  }
}

.title {
  margin: 0;
}

.description {
  text-align: center;

  & > * {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.paragraph {
  margin: var(--screen-sections-gap) auto;
}
