@use "mixins/breakpoint";

.offerFooter {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.text {
  margin: 0;
  text-align: center;
}
