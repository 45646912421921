@use "mixins/breakpoint";

.title {
  font-size: 20px;
  line-height: 24px;
  margin: var(--screen-sections-gap) 16px;
  text-align: center;

  @include breakpoint.tablet {
    font-size: 32px;
    line-height: 44px;
  }
}
