@use "mixins/breakpoint";
@use "variables/color";

.wrapper {
  background-position: center;
  background-size: cover;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  column-gap: 48px;
  margin: 0 auto;

  @include breakpoint.laptop {
    flex-direction: row;
  }
}

.content {
  --hero-vertical-padding: 24px;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  flex: 9;
  padding: var(--hero-vertical-padding) 0 0;

  @include breakpoint.laptop {
    padding-bottom: 24px;
  }
}

.bannerContainer {
  position: relative;
  flex: 10;
  width: 100%;
  max-width: 696px;
  margin: 0 auto;

  &::before {
    content: "";
    display: block;
    padding-top: 81.609%;
  }
}

.banner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.text {
  margin: 0 0 24px;
}

.button {
  width: 100%;

  @include breakpoint.laptop {
    width: auto;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  margin-top: calc(var(--hero-vertical-padding) * -1);
  margin-bottom: var(--hero-vertical-padding);
  background-color: color.$darkViolet;
  color: color.$white;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  border: 1px solid color.$white;
  border-top: none;

  @include breakpoint.laptop {
    position: absolute;
    top: 0;
    margin: 0;
    padding: 13px 16px;
  }
}

.headerText {
  margin: 0;
}
