@use "variables/color";
@use "mixins/breakpoint";

.action {
  align-self: end;
}

.asterisk {
  margin: 0;
  text-align: center;
  white-space: pre-wrap;
  font-size: 12px;

  @include breakpoint.tablet {
    min-height: 40px;
  }
}

.box {
  background-image: linear-gradient(180deg, #3d1f66 -6%, #200c3d 100%);
  border-radius: 16px;
  display: grid;
  grid-gap: 12px;
  grid-template-rows: auto auto 70px auto 1fr;
  padding: 16px;

  @media (min-width: 768px) {
    min-height: 525px;
  }

  &.active {
    background-image: linear-gradient(180deg, #8d00ab -19.37%, #1d004f 92.09%);
  }
}

.description {
  margin: -12px 0 0;
}

.offerBox {
  display: grid;
  grid-gap: 12px;
  grid-template-rows: auto 1fr;
  width: 100%;
  max-width: 380px;
}

.promoText {
  margin: 0;
  padding: 0 12px;
}

.promoWrapper {
  border-radius: 14px;
  color: color.$white;
  display: grid;
  grid-template-rows: 20px 1fr;
  padding: 2px;

  &.active {
    background: color.$club;
  }
}

.separator {
  border-color: transparentize(color.$white, 0.5);
  border-width: 1px 0 0 0;
  margin: 0;
}

.title {
  margin: 0;
}
