.slide {
  min-width: 0;
  flex: 0 0 var(--slide-size);
  margin-left: var(--slide-spacing);
  border-radius: 6px;
  overflow: hidden;
}

.picture {
  display: flex;
  transition: transform 1s ease-in-out;
  width: 100%;

  > img {
    aspect-ratio: 16/9;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
  }

  &:hover {
    transform: scale(1.05);
  }
}
