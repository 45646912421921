@use "variables/color";
@use "variables/layout";

.box {
  align-items: center;
  background: transparentize(color.$white, 0.9);
  border-radius: 8px;
  display: grid;
  padding: (layout.$gutter / 8) (layout.$gutter / 2);
}

.duration {
  color: color.$white;
}

.info {
  margin: 0;
}

.oldPrice {
  align-self: center;
  color: color.$gray;
  text-decoration: line-through;
}

.priceRow {
  align-items: baseline;
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.4em;
  justify-content: start;
  margin: 0;

  &.promo {
    color: color.$club;
  }
}
