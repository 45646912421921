.accordion {
  background: #fff;
  border-radius: 8px;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.08);
}

.animationWrapper {
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.4s ease;
}

.content {
  font-size: 16px;
  padding: 0 16px 8px;

  & > * {
    margin: 0;

    & + * {
      margin-top: 16px;
    }
  }

  a {
    text-decoration: none;
    background: linear-gradient(90deg, currentColor 50%, transparent 50%) bottom no-repeat;
    background-position-x: 100%;
    background-size: 200% 1px;
    color: hsl(204, 100%, 60%);
    transition: all 0.2s ease;

    &:hover {
      background-position-x: 0;
      color: hsl(204, 100%, 40%);
    }
  }

  @media (min-width: 992px) {
    padding: 0 24px 20px;
  }
}

.contentWrapper {
  overflow: hidden;
}

.input {
  position: absolute;
  opacity: 0;
  z-index: -1;

  &:checked ~ .animationWrapper {
    grid-template-rows: 1fr;
  }

  &:checked ~ .title > .titleIcon {
    color: #33adff;
    transform: rotateZ(180deg);
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  background: transparent;
  text-decoration: none;
  border: 0;
  color: #000;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 8px 16px;
  text-align: left;
  width: 100%;

  @media (min-width: 992px) {
    font-size: 22px;
    padding: 20px 24px;
  }
}

.titleIcon {
  min-width: 24px;
  transition: all 0.4s ease;
}
