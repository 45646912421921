@use "mixins/breakpoint";

.promoTop {
  align-items: center;
  display: flex;
  gap: 10px;
}

.title {
  flex: 1;
  font-size: 20px;
  margin: 0;

  @include breakpoint.tablet {
    font-size: 32px;
  }
}

.sideButton {
  font-weight: 700;
  text-decoration: none;
}
