@use "mixins/breakpoint";

.itemSets {
  margin: var(--screen-sections-gap) auto;
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px 24px;
}

.moreButton {
  display: block;
  margin: 16px auto 0;
  width: fit-content;
}

.revealButton {
  display: block;
  margin: 16px auto 0;

  @include breakpoint.tablet {
    display: none;
  }
}
