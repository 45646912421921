@use "variables/color";
@use "mixins/breakpoint";

.teaserGroup {
  width: 100%;

  @include breakpoint.tablet {
    width: calc((100% / 2) - 12px);
  }

  @media (min-width: 992px) {
    width: calc((100% / 3) - 16px);
  }

  overflow: hidden;
  position: relative;
  border-radius: 4px;

  &:hover {
    --scale: 1.05;
  }

  &__mobileNotReveal {
    &:nth-child(n + 4) {
      display: none;

      @include breakpoint.tablet {
        display: block;
      }
    }
  }
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top left;
  transition: transform 1s ease-in-out;
  transform-origin: center center;
  transform: scale(var(--scale, 1));
}

.label {
  position: absolute;
  bottom: 0;
  left: 0;
  background: var(--bg-color);
  font-size: 16px;
  color: color.$white;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  padding: 5px 10px;

  span {
    font-size: 12px;
    margin-left: 10px;
  }

  &__dark {
    color: color.$black;
  }
}
