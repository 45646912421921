@use "mixins/breakpoint";

.clubLogo {
  height: 32px;

  @include breakpoint.tablet {
    height: 40px;
  }
}

.offerTop {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  row-gap: 8px;
}

.slogan {
  margin: 0;
  text-align: center;
}
