@use "mixins/breakpoint";

.homeWrapper {
  --screen-sections-gap: 24px;
  margin-bottom: 40px;

  @include breakpoint.tablet {
    --screen-sections-gap: 40px;
  }

  :global {
    .web-hero-home + .home-carousel {
      margin-top: 16px;
    }
  }
}
