@use "variables/color";
@use "mixins/breakpoint";

.badge {
  background: color.$green;
  border-radius: 4px;
  color: color.$black;
  display: block;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
  padding: 2px 4px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -70%);
  white-space: nowrap;

  @include breakpoint.tablet {
    font-size: 12px;
    line-height: 16px;
    padding: 2px 12px;
    transform: translate(-50%, -50%);
  }
}

.button {
  align-items: center;
  background: #392163 linear-gradient(0deg, #0000 9px, #fff4 9px, #fff4 calc(100% - 9px), #0000 calc(100% - 9px)) left
    no-repeat;
  background-size: 1px 100%;
  border: 0;
  color: color.$white;
  cursor: pointer;
  display: grid;
  font-family: inherit;
  font-size: 14px;
  font-weight: 700;
  grid-auto-flow: row;
  grid-gap: 4px;
  justify-content: center;
  line-height: 20px;
  padding: 10px;
  position: relative;
  text-align: center;

  &:first-child {
    background-image: none;
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  &:hover {
    background-color: lighten(#392163, 10%);
  }

  &.active {
    background-color: #dbc6ff;
    color: color.$almostBlack;

    + .button {
      background-image: none;
    }
  }

  @include breakpoint.tablet {
    background-image: none;
    border-radius: 8px;
    font-size: 16px;
    grid-auto-flow: column;
    line-height: 24px;
    padding: 18px 24px;

    &:first-child,
    &:last-child {
      border-radius: 8px;
    }
  }
}

.icons {
  display: grid;
  font-size: 0;
  grid-auto-flow: column;
  grid-gap: 4px;
  justify-content: center;
}

.icon {
  border-radius: 4px;
  display: block;
  height: 16px;
  width: auto;

  @include breakpoint.tablet {
    height: 20px;
  }
}
