@use "mixins/breakpoint";

.productGridPromo {
  position: relative;
  margin: 0 auto;
}

.backgroundPicture {
  position: absolute;
  inset: 0;
  z-index: -1;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: right;

    @include breakpoint.tablet {
      object-position: center;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 518px;
  padding-bottom: 26px;
  padding-top: 26px;

  @include breakpoint.tablet {
    min-height: 547px;
    padding-bottom: 36px;
    padding-top: 36px;
  }
}

.teaserList {
  margin-bottom: 15px;

  @include breakpoint.tablet {
    flex: 1;
  }
}

.description {
  font-size: 14px;
  max-width: calc(100% - 150px); // 150px to not get covered background completely by description

  > p {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
  }

  @include breakpoint.tablet {
    font-size: 16px;
    max-width: 100%;
  }
}

.action {
  line-height: 20px;
  padding: 10px 24px;
  transition: all 0.2s ease;
  width: fit-content;

  &:hover {
    background-color: #d518ff !important;
    color: #fff !important;
  }

  @include breakpoint.tablet {
    max-width: 300px;
    padding: 14px 32px;
    width: 100%;
  }
}
