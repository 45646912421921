@use "variables/color";
@use "mixins/breakpoint";

.offerBoxes {
  display: grid;
  grid-gap: 24px;
  position: relative;

  @include breakpoint.tablet {
    grid-gap: 40px;
  }
}

.tabPanel {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 12px 24px;
  justify-content: center;
  margin: 0 auto;
  min-height: 570px;
  width: 100%;

  @include breakpoint.tablet {
    align-items: stretch;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
