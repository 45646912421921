@use "variables/color";

.arrowButton {
  color: color.$black;
  backdrop-filter: blur(200px);
  background: transparentize(color.$white, 0.5);
  border: 0;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
  cursor: pointer;
  opacity: var(--arrow-buttons-opacity);
  visibility: var(--arrow-buttons-visibility);
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.arrowButtons {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  right: 16px;
  pointer-events: none;
  justify-content: space-between;
  align-items: center;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
}
