@use "variables/color";

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  place-items: center;
  place-content: center;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  height: 600px;
}

.icon {
  $size: 56;
  $thickness: 10;
  animation: spin 1s linear infinite;
  background: conic-gradient(#0000, color.$club);
  clip-path: path(
    "M 0, #{$size/2} A #{$size/2} #{$size/2} 0 0 1 #{$size} #{$size/2} L #{$size - $thickness} #{$size/2} A #{($size - $thickness*2)/2} #{($size - $thickness*2)/2} 0 0 0 #{$thickness} #{$size/2} A #{($size - $thickness*2)/2} #{($size - $thickness*2)/2} 0 0 0 #{$size - $thickness} #{$size/2} L #{$size} #{$size/2} A #{$size/2} #{$size/2} 0 0 1 0 #{$size/2} Z"
  );
  width: #{$size}px;
  height: #{$size}px;
}
