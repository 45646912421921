@use "mixins/breakpoint";

.faq {
  margin: var(--screen-sections-gap) auto;
  max-width: 1200px;
}

.title {
  font-size: 26px;
  font-weight: 700;
  margin: 0 0 8px;
  text-align: center;
}

.description {
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 24px;
  text-align: center;
  white-space: pre-wrap;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.list {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr;

  @include breakpoint.tablet {
    gap: 16px;
  }
}
