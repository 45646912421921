.argumentersList {
  align-items: start;
  display: grid;
  grid-gap: 8px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.item {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 20px 1fr;
}
