.bannerCarousel {
  --slide-spacing: 16px;
  --slide-size: 85%;

  @media (min-width: 576px) {
    --slide-size: 500px;
  }

  @media (min-width: 768px) {
    --slide-size: 530px;
  }

  --arrow-buttons-visibility: hidden;
  --arrow-buttons-opacity: 0;

  &:hover {
    --arrow-buttons-visibility: visible;
    --arrow-buttons-opacity: 1;
  }

  margin: var(--screen-sections-gap) 0;
}

.viewport {
  position: relative;
  overflow: hidden;
}

.slides {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y pinch-zoom;
}
